import { TeaserModule } from 'designsystem/components/3-organisms/TeaserModule';
import { LinkFeature } from 'features/Modules/Link';
import { RichTextFeature } from 'features/Modules/RichText';
import { useEffect, useState } from 'react';
import { ModuleFeature } from '../DynamicModule';
import { convertForRichTextFeature } from '../RichText/richTextFeatureAdapter';
import { VideoFeature } from '../Video';

export const TeaserModuleFeature: React.FC<Content.TeaserModule> = ({ content, settings }) => {
	const { heading, link, columns, teaserItems, noItemsMessage, imagePosition: globalImagePosition, useWrapper } = content?.properties ?? {};
	const { imageIsPDF, imageHasBorder, noTopMargin, hideImagesOnMobile, mediaAspectRatio } = settings?.properties ?? {};
	const fallbackColumns = columns ? columns : globalImagePosition !== 'Top' ? null : 2;

	const [useLinkWrapper, setUseLinkWrapper] = useState<boolean>(false);
	useEffect(() => {
		setUseLinkWrapper(useWrapper);
	}, [useWrapper]);
	const teaserModuleItems = teaserItems?.map(({ content, settings }) => {
		const { tempImage, picture, entryId, videoUrl, heading, text, link, additionalInfo, date, modals, jobInfo, urlWrapperLink } =
			content?.properties ?? {};

		const imageObj = picture?.length > 0 ? picture[0]?.content?.properties.image : tempImage;
		const { url, focalPoint } = imageObj ?? {};
		const altText = picture?.length > 0 ? picture[0]?.content?.properties.altText : imageObj?.properties?.altText;
		const altTextChecked = altText == '""' ? '' : altText;

		const { style, imagePosition, imageDisplayStyle } = settings?.properties ?? {};
		const styleWithDefault = style ? style : 'White';
		const validatedImagePosition = globalImagePosition === 'Top' ? 'Top' : imagePosition;

		return {
			picture: url && {
				altText: altTextChecked,
				url,
				focalPoint,
			},
			video: (entryId || videoUrl) && (
				<VideoFeature
					documentType="video"
					content={{ properties: { url: videoUrl, entryId: entryId, title: heading } }}
					settings={{ properties: { aspectRatio: mediaAspectRatio } }}
					noBackground={true}
				/>
			),
			heading,
			date,
			text,
			modals:
				modals?.map((modal) => {
					const { modalId, header, body } = modal?.content?.properties ?? {};
					return {
						modalId,
						header,
						body: body?.map((contentModule, index) => {
							return <ModuleFeature contentModule={contentModule} key={index} additionalProps={{ noBackground: true }} />;
						}),
					};
				}) ?? [],
			imageDisplayStyle,
			link: link ? <LinkFeature style="arrow" {...link} /> : undefined,
			backgroundStyle: styleWithDefault,
			imagePosition: imagePosition ? validatedImagePosition : globalImagePosition,
			imageIsPDF,
			imageHasBorder,
			additionalInfo: additionalInfo ? <RichTextFeature content={convertForRichTextFeature(additionalInfo)} /> : jobInfo && jobInfo,
			urlWrapperLink: useLinkWrapper ? (link ? link.url : undefined) : urlWrapperLink,
		};
	});

	return (
		<TeaserModule
			link={link ? <LinkFeature style="arrow" {...link} /> : undefined}
			heading={heading}
			noItemsMessage={noItemsMessage}
			columns={fallbackColumns}
			noTopMargin={noTopMargin}
			hideImagesOnMobile={hideImagesOnMobile}
			aspectRatio={mediaAspectRatio}
			teaserItems={teaserModuleItems?.length > 0 ? teaserModuleItems : null}
		/>
	);
};
